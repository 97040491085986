<template lang="pug">
.drawer(class='lg:drawer-open')
  input#my-drawer-2.drawer-toggle(type='checkbox')
  .drawer-content.flex.flex-col
    // Page content here
    slot

  .drawer-side(class='h-screen')
    label.drawer-overlay(for='my-drawer-2' aria-label='close sidebar')
    ul.menu.p-4.w-80.min-h-full.bg-base-200.text-base-content.bg-white.border-r
      // Sidebar content here
      .w-8.h-8(:style='colorStyle')
      div.mt-7
        UButton.text-white.gap-2.w-max(@click='doCreate' :class='["min-w-[130px]", {"pointer-events-none":isLoading}]' :style="colorStyle")
          Icon.w-4.h-4(name='mingcute:loading-3-fill' class='animate-spin' v-if="isLoading")
          .flex.items-center.gap-2(v-else='')
            Icon.w-4.h-4(name='mdi:plus')
            .font-semibold New Page
      .flex.flex-col.gap-2.mt-4
        NuxtLink.font-semibold.flex.items-center(:to='{ name: "feed" }' class='flex items-center gap-2 min-h-[40px] px-2 py-2.5 rounded-md text-[#64748B]' activeClass='bg-[#F1F5F9] text-[#0F172A]') 
          Icon.w-4.h-4(name='lucide:radio')
          .font-semibold Feed
        NuxtLink.font-semibold.flex.items-center(:to='{ name: "page" }' class='flex items-center gap-2 min-h-[40px] px-2 py-2.5 rounded-md text-[#64748B]' activeClass='bg-[#F1F5F9] text-[#0F172A]') 
          Icon.w-4.h-4(name='heroicons-outline:collection')
          .font-semibold My Pages
        NuxtLink.font-semibold.flex.items-center(:to='{ name: "team" }' class='flex items-center gap-2 min-h-[40px] px-2 py-2.5 rounded-md text-[#64748B]' activeClass='bg-[#F1F5F9] text-[#0F172A]') 
          Icon.w-4.h-4(name='lucide:users')
          .font-semibold Team Pages
        NuxtLink.font-semibold.flex.items-center(:to='{ name: "member" }' class='flex items-center gap-2 min-h-[40px] px-2 py-2.5 rounded-md text-[#64748B]' activeClass='bg-[#F1F5F9] text-[#0F172A]') 
          Icon.w-4.h-4(name='lucide:building')
          .font-semibold Team Members
      .mt-auto.p-2
        .flex.items-center.gap-2.p-2
          figure.w-6.h-6.rounded-lg.overflow-hidden
            img.h-full.w-full.object-cover(:src='account?.metadata?.logo')
          .name.flex-1(class='max-w-[200px] overflow-hidden') {{ account?.email }}
          DropdownMenu
            DropdownMenuTrigger
              .ml-auto
                Icon.w-5.h-5.text-black.cursor-pointer(name='lucide:more-horizontal' class='' @click='')
            DropdownMenuContent.w-56
              DropdownMenuItem.gap-2(@click='gotoProfile')
                Icon.w-4.h-4(name='material-symbols:account-circle')
                span Profile & Billing
              DropdownMenuItem.gap-2(@click='logout')
                Icon.w-4.h-4(name='mdi:logout')
                span Logout
</template>

<script setup lang="ts">
import {
  DropdownMenuItem,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { storeToRefs } from 'pinia'
import { createNew as createPage } from '@/apis/page'
import { useToast } from '~~/components/ui/toast'

const UButton = defineAsyncComponent(
  () => import('@/components/ui/button/Button.vue'),
)

const { toast } = useToast()
const { account } = storeToRefs(useUserStore())
const { logout } = useAccount()
const { colorStyle, color } = useBrandColor()

const router = useRouter()

function gotoProfile() {
  router.push({ name: 'setting' })
}
// https://onepage-api.onrender.com/api/auth/google/callback
// https://onepage-api.onrender.com/api/connect/google/callback

// =========== CREATE NEW PAGE ==============
const form = ref<{
  title: string
  description: any
}>({ title: 'New page', description: '' })
const { mutate: onCreateNew, loading: isLoading } = createPage(form.value)

function doCreate() {
  if (!account.value) {
    toast({
      description: 'Login is required to create a page',
      variant: 'destructive',
      duration: 4000,
    })
    return
  }
  onCreateNew({
    ...form.value,
    publishedAt: new Date(),
    userId: account.value?.id,
  }).then((res) => {
    // Goto detail after created
    const id = res?.data.createPage.data.id
    navigateTo({
      name: 'page-slug',
      params: {
        slug: id,
      },
    })
  })
}
</script>

<style lang="stylus" scoped></style>
