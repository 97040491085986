import { storeToRefs } from 'pinia';


function useAccount() {
  const accountCookie = useCookie('account')

  const { account } = storeToRefs(useUserStore())
  function logout() {
    // remove cookies
    accountCookie.value = null
    // remove account
    account.value = undefined
    // redirect to join page (index page)
    navigateTo({ name: 'join' })
  }
  return {
    logout
  }
}

export default useAccount